import "./input.css";

const Input = ({
  placeholder,
  value,
  style,
  onChange,
  index,
  type = "text"
}) => {
  const getStyle = (style) => {
    if (style) return `input ${style}`;
    return `input`;
  };

  const handleChange = (event) => {
    if (index) onChange(event, index + 1, type);
    else onChange(event, null, type);
  };

  return (
    <input
      className={getStyle(style)}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={(event) => handleChange(event)}
    />
  );
};

export default Input;