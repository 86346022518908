import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import Utilities from "../../common/form/utilities";
import NavBar from "../../../components/NavBar/navBar";
import "./style.css";

class Register extends Form{
    state={
        account:{
            username:"",
            password:"",
            firstName:"",
            company:""
        },
        errors:{}
    };
 
    schema={
        username:Joi.string().email().required().label("Email"),
        password:Joi.string().min(6).required().label("Password"),
        firstName:Joi.string().required().label("First Name"),
        company:Joi.string().required().label("Company")
    }

    doSubmit = () => {
        // now doing the submit thing
    }


    render(){
        const {account,errors}=this.state;
        return(
            <>
                <NavBar/>
                <div className="register_main_container">
                    <div className="register">
                        <div className="welcome-text">Join GrdasFlow for free</div>
                        <div className="register_header">Register to explore possiblities of AutoML.</div>
                        <div className="register_header">Let the inovation's begin.</div>    
                        <form className="register_form" onSubmit={this.handleSubmit}>
                            {this.renderInput(account,"firstName","Name",errors)}
                            {this.renderInput(account,"username","Email",errors)}
                            {this.renderInput(account,"company","Company",errors)}
                            {this.renderInput(account,"password","Password",errors,"password")}
                            {this.renderInput(account,"password","Re-Password",errors,"password")}
                            {this.renderButton("Create Account","register-button")}
                            <Utilities id={"register-text"} label1="Already have an Account?" label2="Log In"/>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default Register;

