import "./textArea.css";

const TextArea = ({
  placeholder,
  text,
  style,
  rows,
  cols,
  onChangeTeaxtArea
}) => {
  const getStyle = (style) => {
    if (style) return "default_text_area";
    return `default_text_area ${style}`;
  };

  return (
    <>
      <textarea
        className={getStyle(style)}
        placeholder={placeholder}
        onChange={(event) => onChangeTeaxtArea(event)}
        rows={rows}
        cols={cols}
      />
      {text.length >= 200 && (
        <div className="warning">you cannot add more than 200 words</div>
      )}
    </>
  );
};

export default TextArea;
