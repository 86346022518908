import { React } from 'react';
import { Route ,Redirect } from "react-router-dom";
import * as authService from "../../../services/authService";

const ProtectedRoute = ({path,component:Component,render}) =>{
    return (
        <Route 
        path={path}
        render={props=>{
            if(!authService.getCurrentUser()) return <Redirect to="/login"/>
            return Component ? <Component {...props} /> : render(props);
        }}
      />
    );
    return null;
};

export default ProtectedRoute;