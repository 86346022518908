import "./NotFound.css"; 
const notFoundPage= "https://ik.imagekit.io/tcpliruedfe/undraw_page_not_found_su7k_u8OQ_2P-b.svg";

const NotFound = ()=>{
    return (
        <div className="logo-container">
            <img src={notFoundPage} alt="Logo_Image"/>
        </div>
    );   
}

export default NotFound; 