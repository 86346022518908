import React, { Component } from "react";
import Labels from "../../components/Label/Label";
import Input from "../../components/common/input/input";
import Select from "../../components/common/select/select";
import Button from "../../components/common/button/button";
import TextArea from "../../components/common/textArea/textArea";
import { style } from "./utilities";
import "./styles.css";

class DatasetForm extends Component {
  state = {
    tittle: "",
    descripion: "",
    task: "",
    taskList: ["Task", "a", "b", "c"],
    type: "",
    typeList: ["Type", "a", "b", "c"],
    visibility: "",
    visibilityList: ["Visibility", "Public", "Private"],
    labels: [],
    limit: 8,
    limitStatus: false,
    file: null
  };

  onChangeInput = (event, index, type) => {
    const { value } = event.target;
    if (index) {
      const { labels } = this.state;
      labels[index - 1] = value;
      this.setState({ labels });
    } else if (type === "file") {
      this.setState({ file: value });
    } else this.setState({ tittle: value });
  };

  addLables = () => {
    const { labels, limit } = this.state;
    if (labels.length < limit) {
      labels.push("");
      this.setState({ labels });
    } else {
      this.setState({ limitStatus: true });
    }
  };

  onDeleteLabel = (index) => {
    let { labels, limit, limitStatus } = this.state;
    labels.splice(index, 1);
    if (limitStatus) limitStatus = false;
    this.setState({ labels, limit, limitStatus });
  };

  onChangeTeaxtArea = (event) => {
    const { value } = event.target;
    let { descripion } = this.state;
    descripion = value;
    this.setState({ descripion });
  };

  onChangeSelect = (event) => {};

  onClickSubmit = (event) => {};

  render() {
    const {
      tittle,
      labels,
      limitStatus,
      descripion,
      file,
      task,
      taskList,
      type,
      typeList,
      visibility,
      visibilityList
    } = this.state;

    const { description, textArea, fileS } = style;

    return (
      <div className="dataSet_form_creation">
        <Input
          value={tittle}
          style={description}
          placeholder={"Enter the Tittle for the Dataset"}
          onChange={this.onChangeInput}
        />
        <TextArea
          cols={10}
          rows={5}
          text={descripion}
          style={textArea}
          placeholder={"Enter the Description for the Dataset"}
          onChangeTeaxtArea={this.onChangeTeaxtArea}
        />
        <Select
          selectedOption={task}
          optionList={taskList}
          onChange={this.onChangeSelect}
        />
        <Select
          selectedOption={type}
          optionList={typeList}
          onChange={this.onChangeSelect}
        />
        <Select
          selectedOption={visibility}
          optionList={visibilityList}
          onChange={this.onChangeSelect}
        />
        <Labels
          labels={labels}
          style={description}
          limitStatus={limitStatus}
          onChange={this.onChangeInput}
          onClickButton={this.addLables}
          onDeleteLabel={this.onDeleteLabel}
        />
        <Input
          value={file}
          type="file"
          style={fileS}
          onChange={this.onChangeInput}
        />
        <Button text={"Submit"} onClick={this.onClickSubmit} />
      </div>
    );
  }
}

export default DatasetForm;
