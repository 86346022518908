import { MdClose } from "react-icons/md";
import Input from "../common/input/input";
import Button from "../common/button/button";
import "./labelStyle.css";

const Labels = ({
  labels,
  onChange,
  onClickButton,
  limitStatus,
  onDeleteLabel
}) => {
  const getButtonText = () => {
    if (labels.length) return "Add More Labels";
    return "Add Labels";
  };

  return (
    <div className="labels_container">
      <div className="labels_heading">
        <span>Create Labels</span>
        <span>(Can not modify after creation Of Dataset)</span>
      </div>
      <div className="label">
        {labels.map((label, index) => (
          <div className="label">
            <Input value={label} onChange={onChange} index={index} />
            <MdClose className="cross" onClick={() => onDeleteLabel(index)} />
          </div>
        ))}
        <Button text={getButtonText()} onClick={onClickButton} />
        {limitStatus && (
          <div className="warning">
            Pls. Use JSON Format , If you want to add more labels
          </div>
        )}
      </div>
    </div>
  );
};

export default Labels;
