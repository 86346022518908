import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { Switch, Route, Redirect } from "react-router-dom";
import DatasetForm from "./containers/Dataset/DatasetForm";
import Dashbaord from "./containers/Dashbaord/Dashboard";

import Logout from "./containers/Logout/Logout";
import Topbar from "./components/Topbar/topbar";
import Footer from "./components/Footer/footer";
import Sidebar from "./components/Sidebar/Sidebar";
import NotFound from "./components/NotFound/NotFound";
import "./styles.css";

class App extends Component {
  state = {
    mobile: true,
    selctedContent: "Dashboard",
    workspace: "Aniket",
    currentUser: {}
  };

  onClickNavbarItem = (title) => {
    if (title !== "GradsFlow") {
      this.setState({ selctedContent: title });
    } else {
      this.setState({ selctedContent: "Dashboard" });
    }
  };

  onClickICon = (title) => {
    if (title === "GradsFlow") {
      const { mobile } = this.state;
      this.setState({ mobile: !mobile });
    }
  };

  getAppcontainer = () => {
    const { mobile } = this.state;
    if (!mobile) return "app_container_mobile";
    return "app_container";
  };

  async componentDidMount() {
    try {
      const jwtToken = localStorage.getItem("token");
      if (jwtToken) {
        const currentUser = jwt_decode(jwtToken);
        this.setState({ currentUser });
      }
    } catch (error) {
    }
  }

  render() {
    const { mobile, selctedContent, currentUser } = this.state;


    return (
      <div className={this.getAppcontainer()}>
        <Topbar selectedModule={selctedContent} workspace={currentUser.email} />
        <Sidebar
          mobile={mobile}
          selctedContent={selctedContent}
          onClickNavbarItem={this.onClickNavbarItem}
          onClickICon={this.onClickICon}
        />
        <div className="content_container">
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path="/dashboard" component={Dashbaord} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/dataset" component={DatasetForm} />
            <Route exact path="/not-found" component={NotFound} />
            <Redirect to="/not-found" />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;