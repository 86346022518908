import "./buttonStyle.css";

const Button = ({ text, style, onClick }) => {
  const getStyle = (style) => {
    if (style) return `button ${style}`;
    return `button`;
  };

  return (
    <button className={getStyle(style)} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
