import React from "react";

const Input=({name,value,errors,...rest})=>{
    return (
    <>
        <input 
            name={name}
            value={value}
            id={name}
            {...rest}
        />
        {errors && <div className="alert">{errors}</div>}
    </>);
};

export default Input;