import "./select.css";

const Select = ({ style, selectedOption, optionList, onChange }) => {
  const getStyle = (style) => {
    if (style) return "default_select";
    return `default_select ${style}`;
  };
  return (
    <select
      className={getStyle(style)}
      value={selectedOption}
      onChange={onChange}
    >
      {optionList.map((option) => (
        <option value={option}>{option}</option>
      ))}
    </select>
  );
};

export default Select;
