import { Link } from "react-router-dom";
import { BsClipboardData } from "react-icons/bs";
import { AiFillProject, AiOutlineMenu } from "react-icons/ai";
import { RiTeamFill } from "react-icons/ri";
import { FiFileText } from "react-icons/fi";
import { FaUsersCog, FaKey } from "react-icons/fa";
import { BiGridAlt, BiUser, BiBookmarks, BiLogOut } from "react-icons/bi";
import "./Sidebar.css";

const Sidebar = ({
  mobile,
  selctedContent,
  onClickNavbarItem,
  onClickICon
}) => {
  const getIonTittleStyle = (title) => {
    if (title !== "GradsFlow" && selctedContent === title)
      return "sidebar-item-active";
    return "sidebar-item";
  };

  const getIconStyle = (title) => {
    if (title && selctedContent === title) return "react-icons-active";
    return "react-icons";
  };

  const content = [
    { Icon: AiOutlineMenu, title: "GradsFlow" },
    { Icon: BiGridAlt, title: "Dashboard" },
    { Icon: BiUser, title: "Profile" },
    { Icon: AiFillProject, title: "Projects" },
    { Icon: BsClipboardData, title: "Dataset" },
    { Icon: BiBookmarks, title: "Bookmark" },
    { Icon: RiTeamFill, title: "Teams" },
    { Icon: FaUsersCog, title: "Services" },
    { Icon: FaKey, title: "Api Key" },
    { Icon: FiFileText, title: "Docs" },
    { Icon: BiLogOut, title: "Logout" }
  ];

  const Components = content.map(({ Icon, title }) => {
    return (
      <Link to={`${title.toLowerCase()}`}>
        <div
          onClick={() => onClickNavbarItem(title)}
          className={getIonTittleStyle(title)}
        >
          <Icon
            onClick={() => onClickICon(title)}
            className={getIconStyle(title)}
            size="3rem"
          />
          {!mobile && <span className="logo">{title}</span>}
        </div>
      </Link>
    );
  });

  return (
    <ul
      className={
        mobile ? "sidebar-items-container" : "sidebar-container-mobile"
      }
    >
      {Components}
    </ul>
  );
};

export default Sidebar;
