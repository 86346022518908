import http from './httpService';
import jwt_decode from "jwt-decode";
import { authApiEndpoint } from "../config.json";

http.setJwt(getJwt());

export async function login(email, password) {
    return await http.post(`${authApiEndpoint}/authentication`, { email, password });
};

export function getJwt() {
    return localStorage.getItem("token");
};

export function getCurrentUser() {
    const jwtToken = localStorage.getItem("token");
    return jwtToken ? jwt_decode(jwtToken) : null;
};

