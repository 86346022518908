import React from 'react';

const Utilities = ({id,label1,label2}) =>{
    return (
        <div id={id}>
            <span className="txt1">{label1}</span>
            <a href="#" className="txt2">{label2}</a>
        </div>
    );
}

export default Utilities;