import { GiTwoCoins } from "react-icons/gi";
import { RiArrowRightSLine } from "react-icons/ri";
import { FaBell } from "react-icons/fa";
import "./topbar.css";

const Topbar = ({ selectedModule, workspace }) => {
  const content = [
    { Icon: "", title: workspace, style: "user-name" },
    { Icon: GiTwoCoins, title: "coins", style: "coins" },
    { Icon: FaBell, title: "notification", style: "notification" }
  ];

  const Components = content.map(({ Icon, title, style }) => {
    if (Icon) {
      return <Icon className={style} size="1.5em" />;
    } else {
      return <div className={style}>Workspace : {workspace}</div>;
    }
  });

  return (
    <div className="topbar-container">
      <div className="current-selected-core-module">
        <span>{selectedModule}</span>
        <RiArrowRightSLine size="1.5rem" className="right-arrow-icon" />
      </div>
      <div className="topbar-profile-items">{Components}</div>
    </div>
  );
};

export default Topbar;
