import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/register-login/login/login";
import Register from "./components/register-login/register/register";
import NotFound from "./components/NotFound/NotFound";
import ProtectedRoute from "./components/common/protectedRoute/protectedRoute";
import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <ProtectedRoute path="/" component={App} />
        <Route exact path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
