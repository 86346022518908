import React from "react";
import "./navBar.css";

const NavBar = () => {
    return(
        <>
        <div className="main-navbar-conatainer">
            <div className="tittle-homepage" >
                    GradsFlow
            </div>
            <nav className="nav-bar-container-small">
                <div>Products</div>
                <div>Pricing</div>
                <div>Request Demo</div>
            </nav>
        </div>
    </>
    );
}

export default NavBar;