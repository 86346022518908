import Joi from "joi-browser";
import {Redirect} from "react-router-dom";
import React from "react";
import Form from "../../common/form/form";
import NavBar from "../../../components/NavBar/navBar";
import Utilities from "../../common/form/utilities";
import * as authService from "../../../services/authService";
import "./style.css";


class Login extends Form {
    state={
        account:{
            username:"",
            password:""
        },
        errors:{}
    };
    
    schema={
        username: Joi.string().email({ minDomainAtoms: 2 , tlds: { allow: true } }).label("Email"),
        password: Joi.string().required().label("Password")
    }

    doSubmit = async () => {
        try{
            const { username , password } = this.state.account;
            const {data}  = await authService.login(username,password);
            console.log(data.authToken,typeof data.authToken)
            localStorage.setItem("token",data.authToken);
            // const { state } = this.props.location
            // const location = state ? state.form.pathname :  "/" ;
            location.replace("/");
        }
        catch(error){
            const expextedError = error.response && (error.response.status == 401 || error.response.status == 404);
            if(expextedError){
                const errors = {...this.state.errors};
                errors.username = error.response.data.error;
                this.setState({errors});
            }
        }
    }

    render()
    {
        if(authService.getCurrentUser()) 
            return <Redirect to="/"/>;

        const {account,errors}=this.state;
        return(
            <>
                <NavBar/>
                <div className="login_main_container">
                    <div className="login">
                        <div className="welcome-text">Welcome Back</div>
                        <div className="login_header">Sign in to explore more</div>
                        <form className="login_form" onSubmit={this.handleSubmit}>
                            {this.renderInput(account,"username","Email",errors)}
                            {this.renderInput(account,"password","Password",errors,"password")}
                            <Utilities id={"forgot-password"} label2="Forgot Password?"/>
                            {this.renderButton("SIGN IN","login-button")}
                            <Utilities id={"register-text"} label1="Do'not have an Account?" label2="Signup"/>
                        </form>
                    </div>
                </div> 
            </>
        );
    };
}

export default Login;
