import axios from "axios";
import { apiEndpoint } from "../config.json";

axios.interceptors.response.use(null, error => {
    const expectedError = error.StatusCode && error.StatusCode >= 400 && error.StatusCode < 500;
    if (!expectedError) {
        console.log("Interceptor Called");
        //need to display a message to the user if sometging went wrong
        //put error to the sentry
        //use react tost to display the tosat notification

    }
    return Promise.reject(error);
});

function setJwt(jwt) {
    // axios.defaults.headers.common["x-auth-token"] = jwt;
};

export default {
    get: axios.get,
    put: axios.put,
    post: axios.post,
    delete: axios.delete,
    setJwt
};