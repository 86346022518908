import {
  FaLinkedin,
  FaFacebookSquare,
  FaYoutube,
  FaTwitterSquare,
  FaDiscord
} from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  const content = [
    { Icon: FaLinkedin, style: "linkdin-icon" },
    { Icon: FaFacebookSquare, style: "facebook-icon" },
    { Icon: FaYoutube, style: "youtube-icon" },
    { Icon: FaTwitterSquare, style: "twitter-icon" },
    { Icon: FaDiscord, style: "discord-icon" }
  ];

  const Components = content.map(({ Icon, style }) => {
    return (
      <div className={style}>
        <Icon size="1.5em" />
      </div>
    );
  });

  return (
    <div className="footer-conatiner">
      <span className="text-warning">some usaual stuff</span>
      <div className="footer-icons">{Components}</div>
    </div>
  );
};

export default Footer;
